import Eventbus from '@libs/eventbus'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { setSchoolBreaks } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useCallback } from 'react'
import { Schoolbreak } from 'src/libs/types/Schoolbreak.ts'

const SchoolBreaksFilter = () => {
  const dispatch = useAppDispatch()

  const checkedSchoolbreaks: string[] = useAppSelector((state) => state.campFilters.filters.schoolBreaks) || []
  const schoolBreaksList = useAppSelector((state) => state.schoolBreaks.schoolBreaksList)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    if (event.target.checked) {
      dispatch(setSchoolBreaks([...new Set([...checkedSchoolbreaks, event.target.value])]))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Date Filter Checked',
        value: event.target.value,
      })
    } else {
      dispatch(setSchoolBreaks(checkedSchoolbreaks.filter((schoolBreak) => schoolBreak !== event.target.value)))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Date Filter UnChecked',
        value: event.target.value,
      })
    }
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const isChecked = useCallback(
    (schoolbreak: Schoolbreak) => {
      return checkedSchoolbreaks.indexOf(schoolbreak.id.toString()) !== -1
    },
    [checkedSchoolbreaks]
  )

  return (
    <Box>
      <Box
        maxHeight={420}
        className="custom-scrollbar"
        sx={{
          overflowY: 'auto',
        }}
      >
        {schoolBreaksList.map((schoolBreak, index) => (
          <Box key={index}>
            <FormGroup>
              <FormControlLabel
                label={schoolBreak.name}
                control={<Checkbox value={schoolBreak.id} onChange={handleChange} checked={isChecked(schoolBreak)} />}
              />
            </FormGroup>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default SchoolBreaksFilter
