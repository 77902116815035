import { combineReducers } from '@reduxjs/toolkit'
import campFiltersReducer from '@store/campFilters/campFiltersSlice.ts'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import campPageReducer from './campPage/campPageSlice'
import campSearchReducer from './campSearch/campSearchSlice'
import globalReducer from './global/globalSlice'
import schoolBreaksReducer from './schoolBreaks/schoolBreaksSlice.ts'
import userListReducer from './userList/userListSlice.ts'

const campFiltersPersistConfig = {
  key: 'filterStorage:22',
  storage: sessionStorage,
}

const rootReducer = combineReducers({
  global: globalReducer,
  campPage: campPageReducer,
  campSearch: campSearchReducer,
  userCampList: userListReducer,
  schoolBreaks: schoolBreaksReducer,
  campFilters: persistReducer(campFiltersPersistConfig, campFiltersReducer),
})

export const reducer = rootReducer
