import { Icons } from '@assets/svg'
import Eventbus from '@libs/eventbus'
import { Alert, Snackbar } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { useState } from 'react'
import { RxHeart } from 'react-icons/rx'
import styled from 'styled-components'

import AddCalendar, { AddCalendarData } from '../AddCalendar.tsx'

const ShareIcon = Icons['share']
const AddToListAndShareButtonsWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .add-to-lists-button {
    font-size: 1.08rem;
    margin-right: 18px;
  }

  .share-button {
    display: flex;
    font-size: 1.08rem;
  }
`
const FixedWrapper = styled.div`
  position: fixed;
  z-index: 2;

  box-shadow:
    0 -4px 8px -2px rgba(16, 24, 40, 0.1),
    0 -2px 4px -2px rgba(16, 24, 40, 0.06);
  left: 0;
  bottom: 0;
  background: white;
  width: 100%;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const FixedFooterShareButtons = (props: {
  className?: string
  onAddToFavoritesClicked: () => void
  calendarData?: AddCalendarData
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)

  const onCopyTextToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
          value: 'Clicked Share Camp',
        })
        setIsSnackbarOpen(true)
      })
      .catch(() => {
        console.warn('Could not write data to clipboard')
      })
  }
  return (
    <>
      <FixedWrapper data-testid={'camp-details-fixed-wrapper'}>
        {!props.calendarData ? (
          <AddToListAndShareButtonsWrapper className={props.className}>
            <Button
              rounded={true}
              icon={<RxHeart />}
              size={'large'}
              style={{ margin: '0 16px 0 0', padding: '0 2rem' }}
              title="Add to lists"
              variant="primary"
              className="add-to-lists-button"
              onClick={props.onAddToFavoritesClicked}
            />
            <Button
              rounded
              icon={<ShareIcon color="black" />}
              variant="secondary"
              title={'Share'}
              className="add-to-lists-button"
              style={{ margin: '0' }}
              onClick={onCopyTextToClipboard}
            />
          </AddToListAndShareButtonsWrapper>
        ) : (
          <>
            <AddCalendar data={props.calendarData} />
            <Button
              icon={<RxHeart fontSize="1.25rem" />}
              size="large"
              variant="primary"
              rounded={true}
              onClick={props.onAddToFavoritesClicked}
            />
            <Button
              icon={<ShareIcon color="black" />}
              size="small"
              variant="secondary"
              rounded={true}
              onClick={onCopyTextToClipboard}
            />
          </>
        )}
      </FixedWrapper>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity="success">Link copied successfully, share it, don&apos;t hold back!</Alert>
      </Snackbar>
    </>
  )
}

export default FixedFooterShareButtons
