import { decorateEntityIds } from '@decorators/decorateEntityIds.ts'
import { decorateInterestsToTree } from '@decorators/decorateInterestsToTree.ts'
import { findEntityById } from '@decorators/findEntityById.ts'
import { getLastDate } from '@decorators/number-formatter.ts'
import { api, apiResponse } from '@libs/api'
import { Camp } from '@libs/types/Camp.ts'
import { Distance } from '@libs/types/Distance.ts'
import { Facility } from '@libs/types/Facility.ts'
import { InterestList } from '@libs/types/Interest.ts'
import { Provider } from '@libs/types/Provider.ts'
import { Requirement } from '@libs/types/Requirement.ts'
import { UserList } from '@libs/types/UserList.ts'
import {
  getProviderList,
  setAdditionalOptions,
  setDayLength,
  setDistanceIsZipCodeIncluded,
  setDistanceMiles,
} from '@store/campFilters/campFiltersSlice'
import { setUserList } from '@store/userList/userListSlice.ts'
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects'
import { Schoolbreak } from 'src/libs/types/Schoolbreak.ts'

import { getBadgesFromInterest } from '../../libs/helpers.ts'
import redis from '../../libs/lockr.ts'
import { timeSpanToDecimal } from '../../libs/timespan-to-decimal.ts'
import {
  getInterestList,
  resetFilters,
  setCalendarDate,
  setCheckedCities,
  setCheckedInterests,
  setCheckedRequirements,
  setDateRange,
  setDayLengthDropOffTime,
  setDayLengthLength,
  setDayLengthPickUpTime,
  setDistance,
  setDistanceAllowBussing,
  setDistanceAllowOvernight,
  setDistanceZipCode,
  setHideTentative,
  setIncludeBussing,
  setInterestList,
  setKidsAges,
  setPricePerDay,
  setPricePerDayIncludeUnknown,
  setPricePerDayRange,
  setPricePerDayScholarshipsOnly,
  setProviderList,
  setProviders,
  setRegistrationStatusClosed,
  setRegistrationStatusNotYetOpen,
  setRegistrationStatusOpen,
  setSchoolBreaks,
  setSearchTerm,
  setShowOnlyLunchIncluded,
  setSort,
} from './../campFilters/campFiltersSlice'
import {
  getCampList,
  setCampList,
  setCampListLoading,
  setFilteredCampList,
  setFilteringIsLoading,
  setZipCodeList,
} from './campSearchSlice'
import { getCampListSelector, getFiltersSelector } from './selectors.ts'

export const PRICE_PER_DAY_RANGE: { MIN: number; MAX: number; TOP_LIMIT: number } = {
  MIN: 0,
  MAX: 1000,
  TOP_LIMIT: 350,
}

function* loadDataFromAPI() {
  yield put(setCampListLoading(true))
  const { data: interests } = yield call(api.getInterests)
  const { data: providers } = yield call(api.getProviders)
  const { data: requirements } = yield call(api.getRequirements)
  const { data: userLists } = (yield call(api.getUserList)) as apiResponse<UserList[]>
  const facilities = (yield call(api.getFacilities)) as Facility[]
  const addresses = (yield call(api.getAddresses)) as Record<number, string>
  const zipcodes = (yield call(api.getZipcodes)) as Record<number, number>
  const bussingaddresses = (yield call(api.getBussingAddresses)) as Record<number, any>

  const interestList: InterestList[] = yield call(decorateInterestsToTree, interests)

  yield put(setInterestList(interestList))
  yield put(setProviderList(providers.sort((a: Provider, b: Provider) => a.name.localeCompare(b.name))))

  let camps = (yield select(getCampListSelector)) as Camp[]

  if (!camps || camps?.length === 0) {
    const { data } = yield call(api.getCamps)
    camps = data

    camps.map((camp: Camp) => {
      camp.badges = getBadgesFromInterest(interestList, camp.interests || [])
      const provider = findEntityById(camp.corporate_provider, providers) as Provider
      const program_provider = findEntityById(camp.program_provider, providers) as Provider
      camp.has_scholarship = provider.scholarship_info_url !== null
      camp.corporate_provider_name = provider.name

      camp.program_provider_name = program_provider?.name || ''
      camp.requirements_decorated = decorateEntityIds<Requirement>(camp.requirements, requirements)

      const facility = facilities[camp.facility]
      camp.facility_name = facility?.name || ''

      // @ts-ignore
      delete camp?.zipcode_distances
      // @ts-ignore
      delete camp?.address
      // @ts-ignore
      delete camp?.bussing_options

      camp.zipcode_bussing_distances = {}
      camp.bussing_options = []

      if (facility?.address) {
        camp.address = addresses[facility.address] || ''
        camp.address_id = facility.address
        camp.bussing_addresses.forEach((bussingAddressId) => {
          const bussingAddress = bussingaddresses[bussingAddressId]
          const addressId = bussingAddress?.address
          if (addressId) {
            const address = addresses[addressId] || ''
            if (address) {
              if (camp.bussing_options) {
                camp.bussing_options.push({ ...bussingAddress, addressName: address })
              }
            }
          }
        })
      }

      if (userLists) {
        userLists.map((userList: UserList) => {
          if (userList.camps.includes(camp.id)) {
            camp.is_favorite = true
          }
        })
      }
    })
  }
  const uniqueZipCodes: string[] = [...new Set(Object.values(zipcodes))].map(String)
  userLists.map((list) => {
    list.camps_decorated = decorateEntityIds<Camp>(list.camps, camps)
  })

  yield put(setCampList(camps))
  yield put(setUserList(userLists))
  yield put(setZipCodeList(uniqueZipCodes))
  yield put(setCampListLoading(false))
}

export function* filterByPastDates(campList: Camp[]): Generator<SelectEffect, Camp[], Camp[]> {
  return campList.filter((camp: Camp) => {
    const dates = camp.dates
    if (!dates) {
      return false
    }
    return getLastDate(dates) >= new Date().toISOString().split('T')[0]
  })
}

export function* filterByKidsAges(campsList: Camp[], kidsAges: number[]): Generator<SelectEffect, Camp[], Camp[]> {
  if (!Array.isArray(kidsAges)) {
    return campsList
  }

  if (kidsAges.length === 0) {
    return campsList
  }

  const camps: Camp[] = []
  kidsAges.forEach((age) => {
    campsList.forEach((camp) => {
      if (camp.ages_from <= age && camp.ages_to >= age) {
        camps.push(camp)
      }
    })
  })
  return camps.filter((camp, index, self) => self.findIndex((c) => c.id === camp.id) === index)
}

export function* filterByDistance(
  campList: Camp[],
  distances: Distance[] | undefined,
  bussingAddresses: Record<number, any>,
  miles: number,
  zipCode: string,
  bussing: boolean,
  overnight: boolean,
  isZipcodeIncluded: boolean
): Generator<SelectEffect, Camp[], Camp[]> {
  if (!zipCode) {
    return campList
  }
  if (!isZipcodeIncluded) {
    return campList
  }
  if (zipCode.length !== 5) {
    return campList
  }

  if (!distances) {
    return campList
  }

  // Get the list of address IDs that are within the selected distance
  const addressIdsWithinDistance = distances
    .filter((addressDistance: Distance) => addressDistance.distance <= miles)
    .map((addressDistance: Distance) => addressDistance.address)

  // Filter camps based on distance and bussing criteria
  return campList.filter((camp) => {
    // If the camp is overnight and the filter requests it, always include it
    if (overnight && camp?.day_length === 'OVERNIGHT') {
      return true
    }

    // Check if any of the bussing addresses are within the distance
    const isBussingAddressWithinDistance = camp.bussing_addresses.some((bussingAddressId) => {
      const bussingAddress = bussingAddresses[bussingAddressId]
      const addressId = bussingAddress?.address
      return addressIdsWithinDistance.includes(addressId)
    })

    // Check if the camp's own address is within the distance
    const isCampAddressWithinDistance = camp.address_id && addressIdsWithinDistance.includes(camp.address_id)

    // Return true if the camp is within the distance either by its own address or via bussing
    return isCampAddressWithinDistance || (bussing && isBussingAddressWithinDistance)
  })
}

function* filterByTentative(campList: Camp[], hideTentative: boolean): Generator<SelectEffect, Camp[], Camp[]> {
  if (hideTentative) {
    return campList.filter((camp) => !camp.tentative)
  }
  return campList
}

function* filterByDayLength(campList: Camp[], dayLength: string): Generator<SelectEffect, Camp[], Camp[]> {
  if (dayLength === 'All') {
    return campList
  } else if (dayLength === 'full') {
    return campList.filter((camp) => camp.day_length === 'FULL' || camp.half_day_combinable)
  } else if (dayLength === 'halfAm') {
    return campList.filter((camp) => camp.day_length === 'HALF_AM')
  } else if (dayLength === 'halfPm') {
    return campList.filter((camp) => camp.day_length === 'HALF_PM')
  } else if (dayLength === 'overnight') {
    return campList.filter((camp) => camp.day_length === 'OVERNIGHT')
  }

  return campList
}

function* filterByInterests(campList: Camp[], checkedInterests: number[]): Generator<SelectEffect, Camp[], Camp[]> {
  if (!Array.isArray(checkedInterests)) {
    return campList
  }
  if (checkedInterests === undefined) {
    return campList
  }
  if (checkedInterests.length === 0) {
    return campList
  }

  return campList.filter((camp) => checkedInterests.some((interest) => camp.interests.includes(interest)))
}

export function* filterByPickupTime(campList: Camp[], pickUpTime: number[]): Generator<SelectEffect, Camp[], Camp[]> {
  if (pickUpTime[0] === 4.3 && pickUpTime[1] === 23.644646) {
    return campList
  }

  return campList.filter((camp) => {
    const times = [camp.latest_pickup_time, camp.end_time, camp.extended_latest_pickup_time]
      .filter((time): time is string => time !== null && time !== undefined)
      .map((time) => timeSpanToDecimal(time))

    if (times && times.length === 0) {
      return false
    }

    const minTime = Math.min(...times)
    const maxTime = Math.max(...times)

    let pickUpTimeStart = pickUpTime[0]
    if (pickUpTimeStart === 4.3) {
      pickUpTimeStart = 0
    }

    let pickUpTimeEnd = pickUpTime[1]
    if (pickUpTimeEnd === 23.644646) {
      pickUpTimeEnd = 24
    }

    return minTime <= pickUpTimeEnd && maxTime >= pickUpTimeStart
  })
}

export function* filterByDropOffTime(campList: Camp[], dropOffTime: number[]): Generator<SelectEffect, Camp[], Camp[]> {
  // Tackle any/any
  if (dropOffTime[0] === 4.3 && dropOffTime[1] === 23.644646) {
    return campList
  }

  return campList.filter((camp) => {
    const times = [camp.start_time, camp.earliest_dropoff_time, camp.extended_earliest_dropoff_time]
      .filter((time): time is string => time !== null && time !== undefined)
      .map((time) => timeSpanToDecimal(time))

    if (times && times.length === 0) {
      return false
    }

    const minTime = Math.min(...times)
    const maxTime = Math.max(...times)

    let dropOffTimeStart = dropOffTime[0]
    if (dropOffTimeStart === 4.3) {
      dropOffTimeStart = 0
    }

    let dropOffTimeEnd = dropOffTime[1]
    if (dropOffTimeEnd === 23.644646) {
      dropOffTimeEnd = 24
    }

    return minTime <= dropOffTimeEnd && maxTime >= dropOffTimeStart
  })
}

export function* filterByPricePerDay(
  campList: Camp[],
  range: [number, number],
  includeUnknown: boolean
): Generator<SelectEffect, Camp[], Camp[]> {
  let unknownPriceCamps: Camp[] = []

  if (range[0] === PRICE_PER_DAY_RANGE.MIN && range[1] === PRICE_PER_DAY_RANGE.MAX) {
    return includeUnknown ? campList : campList.filter((camp: Camp) => camp.daily_price !== null)
  }

  if (includeUnknown) {
    unknownPriceCamps = campList.filter((camp: Camp) => camp.daily_price === null)
  }

  const filteredCampList = campList.filter((camp: Camp) => {
    if (camp.daily_price === null) {
      return false
    }
    const dailyPrice = parseInt(camp.daily_price)
    return dailyPrice >= range[0] && dailyPrice <= range[1]
  })

  return [...filteredCampList, ...unknownPriceCamps]
}

function* filterByDateRange(campList: Camp[], dateRange: [string, string]): Generator<SelectEffect, Camp[], Camp[]> {
  if (!dateRange || dateRange[0] === '') {
    return campList
  }

  const startDate = dateRange[0]
  const endDate = dateRange[1] ?? startDate

  return campList.filter((camp) => {
    return (
      camp.dates &&
      camp.dates.some((date) => {
        const campDate = new Date(date).toISOString().split('T')[0]
        return campDate >= startDate && campDate <= endDate
      })
    )
  })
}
function* filterByCheckedRequirements(
  campList: Camp[],
  checkedRequirements: { [reqId: number]: { include: boolean; exclude: boolean } }
): Generator<SelectEffect, Camp[], Camp[]> {
  if (!checkedRequirements || Object.keys(checkedRequirements).length === 0) {
    return campList
  }

  return campList.filter((camp) => {
    return Object.entries(checkedRequirements).every(([reqId, { include, exclude }]) => {
      const hasRequirement = camp.requirements.includes(Number(reqId))
      // if (!hasRequirement) {
      //   return true
      // }
      if (include) {
        return hasRequirement
      } else if (exclude) {
        return !hasRequirement
      }

      return true // If neither include nor exclude is selected, don't filter by this requirement
    })
  })
}

function* filterBySchoolBreaks(
  campList: Camp[],
  schoolBreaks: string[]
): Generator<SelectEffect, Camp[], Schoolbreak[]> {
  if (!schoolBreaks) {
    return campList
  }
  if (schoolBreaks.length === 0) {
    return campList
  }
  return campList.filter((camp) =>
    camp.school_break.some((schoobreak: any) => schoolBreaks.includes(schoobreak.toString()))
  )
}

function* filterByCheckedCities(
  campList: Camp[],
  checkedCities: string[],
  includeBussing: boolean = false
): Generator<SelectEffect, Camp[], Camp[]> {
  if (!checkedCities) {
    return campList
  }
  if (checkedCities.length === 0) {
    return campList
  }

  const lowerCaseCheckedCities = checkedCities.map((city) => city.toLowerCase())

  return campList.filter((camp) => {
    const campAddress = camp.address?.toLowerCase() || ''
    const bussingOptions = (camp.bussing_options || [])
      .map((item) => `${item?.prefix}${item.addressName}${item.suffix}`)
      .map((option) => option.toLowerCase())
    if (includeBussing) {
      return (
        lowerCaseCheckedCities.some((searchCity) => campAddress.includes(searchCity)) ||
        bussingOptions.some((option) => lowerCaseCheckedCities.some((searchCity) => option.includes(searchCity)))
      )
    } else {
      return lowerCaseCheckedCities.some((searchCity) => campAddress.includes(searchCity))
    }
  })
}

function* filterByScholarshipOnly(
  campList: Camp[],
  scholarshipOnly: boolean = false
): Generator<SelectEffect, Camp[], Camp[]> {
  if (!scholarshipOnly) {
    return campList
  }

  return campList.filter((camp: Camp) => camp.has_scholarship)
}

// function* filterByBussingOption(campList: Camp[], )
function* filterByLunchIncluded(
  campList: Camp[],
  showOnlyLunchIncluded: boolean
): Generator<SelectEffect, Camp[], Camp[]> {
  if (showOnlyLunchIncluded) {
    return campList.filter((camp) => camp.lunch_included)
  }
  return campList
}

function* filterByRegistrationStatus(
  campList: Camp[],
  registrationStatus: {
    open: boolean
    closed: boolean
    full: boolean
    notYetOpen: boolean
  }
): Generator<SelectEffect, Camp[], Camp[]> {
  if (registrationStatus.open) {
    return campList.filter((camp) => camp.registration_status === 'OPEN')
  } else if (registrationStatus.closed) {
    return campList.filter(
      (camp) =>
        camp.registration_status === 'CLOSED' ||
        camp.registration_status === 'WAITLIST' ||
        camp.registration_status === 'FULL'
    )
  } else if (registrationStatus.notYetOpen) {
    return campList.filter((camp) => camp.registration_status === 'NOT YET OPEN')
  }
  return campList
}

function* sortBy(campList: Camp[], by: string): Generator<SelectEffect, Camp[], Camp[]> {
  switch (by) {
    case 'starting-date':
      return campList.sort((a, b) => {
        if (a.dates?.length > 0 && b.dates?.length > 0) {
          const dateA = new Date(a.dates[0])
          const dateB = new Date(b.dates[0])

          return dateA.getTime() - dateB.getTime()
        }
        return 0
      })
    case 'newest':
      return campList.sort((a, b) => {
        return b.id - a.id
      })
    case 'registration-date':
      return campList.slice().sort((a, b) => {
        const dateA = a.registration_open_date ? new Date(a.registration_open_date) : null
        const dateB = b.registration_open_date ? new Date(b.registration_open_date) : null

        if (dateA === null && dateB === null) {
          return 0
        }
        if (dateA === null) {
          return 1
        }
        if (dateB === null) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      })
    case 'daily-price-low':
      return campList.slice().sort((a, b) => {
        const priceA = a.daily_price ? parseFloat(a.daily_price) : null
        const priceB = b.daily_price ? parseFloat(b.daily_price) : null

        if (priceA === null && priceB === null) {
          return 0
        }
        if (priceA === null) {
          return 1
        }
        if (priceB === null) {
          return -1
        }

        return priceA - priceB
      })
    case 'daily-price-high':
      return campList.slice().sort((a, b) => {
        const priceA = a.daily_price ? parseFloat(a.daily_price) : null
        const priceB = b.daily_price ? parseFloat(b.daily_price) : null

        if (priceA === null && priceB === null) {
          return 0
        }
        if (priceA === null) {
          return 1
        }
        if (priceB === null) {
          return -1
        }

        return priceB - priceA
      })
    default:
      break
  }

  return campList
}

function* filterBySearch(campList: Camp[], searchTerm: string): Generator<SelectEffect, Camp[], Camp[]> {
  function deepSearch(obj: any, term: string): boolean {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (deepSearch(obj[key], term)) {
          return true
        }
      } else if (
        key !== 'image_url' &&
        (typeof obj[key] === 'string' || key === 'id') &&
        obj[key].toString().toLowerCase().includes(term.toLowerCase())
      ) {
        return true
      }
    }
    return false
  }
  return campList.filter((camp) => deepSearch(camp, searchTerm))
}

function* filterByProviders(campList: Camp[], providers: string[]): Generator<SelectEffect, Camp[], Camp[]> {
  if (providers.length === 0) {
    return campList
  }

  return campList.filter(
    (camp) =>
      providers.includes(camp.corporate_provider.toString()) || providers.includes(camp?.program_provider?.toString())
  )
}

export function* applyFilterCamps(): Generator<PutEffect | SelectEffect | CallEffect, void> {
  yield put(setFilteringIsLoading(true))
  const filteringState = yield select(getFiltersSelector)
  const {
    filters: {
      kidsAges,
      pricePerDay,
      registrationStatus,
      dayLength,
      dateRange,
      schoolBreaks,
      cities,
      distance,
      searchTerm,
      checkedInterests,
      additionalOptions,
      selectedProviders,
      checkedRequirements,
    },
    sort: { by },
  } = filteringState as ReturnType<typeof getFiltersSelector>

  let campList: Camp[] = (yield select(getCampListSelector)) as Camp[]

  campList = (yield call(filterByPastDates, campList)) as Camp[]
  campList = (yield call(filterByKidsAges, campList, kidsAges)) as Camp[]
  campList = (yield call(filterByDayLength, campList, dayLength.length)) as Camp[]
  campList = (yield call(filterByInterests, campList, checkedInterests)) as Camp[]
  campList = (yield call(filterByDateRange, campList, dateRange)) as Camp[]
  campList = (yield call(filterByProviders, campList, selectedProviders)) as Camp[]
  campList = (yield call(filterByTentative, campList, additionalOptions.hideTentative)) as Camp[]
  campList = (yield call(filterByPickupTime, campList, dayLength.pickUpTimePm)) as Camp[]
  campList = (yield call(filterByDropOffTime, campList, dayLength.dropOffTimeAm)) as Camp[]
  campList = (yield call(filterByPricePerDay, campList, pricePerDay.range, pricePerDay.includeUnknown)) as Camp[]
  campList = (yield call(filterBySchoolBreaks, campList, schoolBreaks)) as Camp[]
  campList = (yield call(filterByLunchIncluded, campList, additionalOptions.showOnlyLunchIncluded)) as Camp[]
  campList = (yield call(filterByCheckedCities, campList, cities.checkedCities, cities.includeBussing)) as Camp[]
  campList = (yield call(filterByScholarshipOnly, campList, pricePerDay.scholarshipsOnly)) as Camp[]
  campList = (yield call(filterByRegistrationStatus, campList, registrationStatus)) as Camp[]
  campList = (yield call(filterByCheckedRequirements, campList, checkedRequirements)) as Camp[]

  let distances: Distance[] | undefined = undefined
  if (distance.zipCode && distance.zipCode.length === 5) {
    distances = (yield call(api.getDistances, distance.zipCode)) as Distance[]
  } else {
    redis.rm('api:v2:distances')
  }
  const bussingAddresses = (yield call(api.getBussingAddresses)) as Record<number, any>

  campList = (yield call(
    {
      context: null,
      fn: filterByDistance,
    },
    campList,
    distances,
    bussingAddresses,
    distance.miles,
    distance.zipCode,
    distance.allowBussing,
    distance.allowOvernight,
    distance.isZipCodeIncluded
  )) as Camp[]

  campList = (yield call(filterBySearch, campList, searchTerm)) as Camp[]

  campList = (yield call(sortBy, campList, by)) as Camp[]

  yield put(setFilteredCampList(campList))
  yield put(setFilteringIsLoading(false))
}

export default function* campSearchSaga() {
  yield takeLatest([getCampList, getInterestList, getProviderList], loadDataFromAPI)

  yield takeLatest(
    [
      setCampList,
      resetFilters,

      //Kids Age
      setKidsAges,

      // Date Filter
      setSchoolBreaks,
      setCalendarDate,
      setDateRange,

      // Additional Options
      setAdditionalOptions,
      setHideTentative,
      setShowOnlyLunchIncluded,

      // Distance
      setDistance,
      setDistanceMiles,
      setDistanceZipCode,
      setDistanceAllowBussing,
      setDistanceAllowOvernight,
      setDistanceIsZipCodeIncluded,

      // Day Length
      setDayLength,
      setDayLengthPickUpTime,
      setDayLengthDropOffTime,
      setDayLengthLength,

      // Registration Status
      setRegistrationStatusOpen,
      setRegistrationStatusNotYetOpen,
      setRegistrationStatusClosed,

      // Interests
      setCheckedInterests,
      setCheckedRequirements,

      // Filter by City Search
      setCheckedCities,
      setIncludeBussing,

      // Price Per Day
      setPricePerDay,
      setPricePerDayRange,
      setPricePerDayScholarshipsOnly,
      setPricePerDayIncludeUnknown,

      // Providers
      setProviderList,
      setProviders,

      // Search Bar
      setSearchTerm,

      // Sort
      setSort,
    ],
    applyFilterCamps
  )
}
